import React from 'react'
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom'


const PrivateRoute = (props) => {

    const {Component}=props;
    const navigate=useNavigate();
    useEffect(()=>{
        let login=localStorage.getItem('login');
        if(!login){
            navigate('/login')
        }
    })
    return(
        <>
        <Component/>
        </>
    )

   
  
}

export default PrivateRoute
