import React from 'react'
import { Link } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'

function Socialmedia() {
  return (
    <>
    <div className="container-xxl bg-white p-0">
    <div className="container-xxl position-relative p-0">
    <Header/>
    <div className="container-xxl py-5 bg-danger hero-header">
    <div className="container my-5 py-5 px-lg-5">
        <div className="row g-5 py-5">
            <div className="col-12 text-center">
                <h1 className="text-white animated slideInDown">Social Media Marketing</h1>
                <hr className="bg-white mx-auto mt-0" style={{width: "90px"}}/>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-center">
                        <li className="breadcrumb-item"><a className="text-white" href='/'>Home</a></li>
                        <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                        <li className="breadcrumb-item text-white active" aria-current="page">Social Media Marketing</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</div>

<div className='container'>

<article className="entry">
<div className="entry-img">
  <img
    src="img/socialmedia.jpg"
    alt=""
    width="100%"
    className="img-fluid"
  />
</div>

<h2 className="entry-title py-4">
  <a href="https://en.wikipedia.org/wiki/Social_media_marketing">Social Media Marketing</a>
</h2>

{/* <div className="entry-meta">
  <ul>
    <li className="d-flex align-items-center">
      <i className="bi bi-person"></i>{" "}
      <a href="blog-single.html">John Doe</a>
    </li>
    <li className="d-flex align-items-center">
      <i className="bi bi-clock"></i>{" "}
      <a href="blog-single.html">
        <time datetime="2020-01-01">Jan 1, 2020</time>
      </a>
    </li>
    <li className="d-flex align-items-center">
      <i className="bi bi-chat-dots"></i>{" "}
      <a href="blog-single.html">12 Comments</a>
    </li>
  </ul>
</div> */}
<br />
<div className="entry-content">
  <p style={{ textAlign: "justify" }}>
    Social media marketing (also known as digital marketing and
    e-marketing) is the use of social media—the platforms on
    which users build social networks and share information—to
    build a company's brand, increase sales, and drive website
    traffic. In addition to providing companies with a way to
    engage with existing customers and reach new ones, SMM has
    purpose-built data analytics that allows marketers to track
    the success of their efforts and identify even more ways to
    engage.
    <br />
    <br /> At the start of 2023, there were 4.76 billion social
    media users globally—over 59% of the world’s population.
    DataReportal. "Global Social Media Statistics." With over
    80% of consumers reporting that social media—especially
    influencer content—significantly impacts buying decisions,
    marketers across industries are driving the evolution of
    social media marketing (SMM) from a stand-alone tool to a
    multipronged source of marketing intelligence on an
    increasingly important—and growing—audience.
  </p>
  <div className="read-more">
    <a href="https://en.wikipedia.org/wiki/Social_media_marketing" type="button" className="btn btn-info">
      Read More
    </a>
  </div>
</div>
</article>
</div>




<Footer/>
</div>

</>
  )
}

export default Socialmedia
