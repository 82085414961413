import React from 'react'
import Footer from './Footer'
import Header from './Header'

const EmailMarketing = () => {
  return (
    <>
    <div className="container-xxl bg-white p-0">
    <div className="container-xxl position-relative p-0">
    <Header/>
    <div className="container-xxl py-5 bg-danger hero-header">
    <div className="container my-5 py-5 px-lg-5">
        <div className="row g-5 py-5">
            <div className="col-12 text-center">
                <h1 className="text-white animated slideInDown">Email Marketing</h1>
                <hr className="bg-white mx-auto mt-0" style={{width: "90px"}}/>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb justify-content-center">
                        <li className="breadcrumb-item"><a className="text-white" href='/'>Home</a></li>
                        <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                        <li className="breadcrumb-item text-white active" aria-current="page">Email Marketing</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
</div>

<div className='container'>
<article className="entry">
                <div className="entry-img">
                  <img
                    src="img/EmailM.png"
                    alt=""
                    width="100%"
                    className="img-fluid"
                  />
                </div>

                <h2 className="entry-title py-4">
                  <a href="https://en.wikipedia.org/wiki/Email_marketing">Email Marketing</a>
                </h2>

                {/* <div className="entry-meta">
                  <ul>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-person"></i>{" "}
                      <a href="blog-single.html">John Doe</a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-clock"></i>{" "}
                      <a href="blog-single.html">
                        <time datetime="2020-01-01">Jan 1, 2020</time>
                      </a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-chat-dots"></i>{" "}
                      <a href="blog-single.html">12 Comments</a>
                    </li>
                  </ul>
                </div> */}
                <br/>
                <div className="entry-content">
                  <p style={{textAlign:'justify'}}>
                    This beginner’s guide will explain what email marketing is,
                    how it works, and how to get started. By the end, you’ll
                    have a solid grasp of the basics along with the know-how to
                    launch an effective email marketing strategy for your
                    business. Email marketing is the most profitable and
                    cost-effective direct marketing channel, generating an
                    average return on investment of $42 for every $1 spent. For
                    this reason alone, email should be a key pillar of your
                    digital marketing strategy. Not doing any email marketing is
                    like leaving money laying out on the table.
                    <br />
                    <br />
                    But if you’re a bit confused about where to start, that’s
                    totally normal. Email is a vast discipline. It’s easy for
                    beginners to get lost in a sea of tools, techniques, and
                    terminology.
                  </p>
                  <div className="read-more">
                  <a href="https://en.wikipedia.org/wiki/Email_marketing" type="button" className="btn btn-info">
                      Read More
                    </a>
                  </div>
                </div>
              </article>
              </div>
              <Footer/>
              </div>

    </>
  )
}

export default EmailMarketing
