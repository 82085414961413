
import axios from "axios";
import React, { useState } from "react";
import uuid from 'react-uuid'; 
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

function Contact() {
    const UniqueId=uuid();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject,setSubject]=useState("");
    const [message,setMessage]=useState("");
    const [phone,setPhone]=useState('');
    const navigate = useNavigate();
    const handlesubmit = (e) => {
      e.preventDefault();
      axios
        .post("https://649ee87e245f077f3e9d1590.mockapi.io/myastrowalk", {
            Id:UniqueId,
          Name: name,
          Email:email,
          Subject: subject,
          Message:message,
          Phone:phone,
        }).then(() => {
            console.log(name, email);
          });
      console.log(name, email);
      alert("Successfully Submit details")
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
      setPhone("");
      navigate('/')

    };


  return (
    <div className="container-xxl bg-white p-0">
        
        
        <div className="container-xxl position-relative p-0">
            <Header/>

            <div className="container-xxl py-5 bg-danger hero-header">
                <div className="container my-5 py-5 px-lg-5">
                    <div className="row g-5 py-5">
                        <div className="col-12 text-center">
                            <h1 className="text-white animated slideInDown">Contact</h1>
                            <hr className="bg-white mx-auto mt-0" style={{width: "90px"}}/>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><a className="text-white" href="#">Home</a></li>
                                    <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                                    <li className="breadcrumb-item text-white active" aria-current="page">Contact</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      



        <div className="container-xxl py-5">
            <div className="container py-5 px-lg-5">
                <div className="wow fadeInUp" data-wow-delay="0.1s">
                    <p className="section-title text-secondary justify-content-center"><span></span>Contact Us<span></span></p>
                    <h1 className="text-center mb-5">Contact For Any Query</h1>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-7">
                        <div className="wow fadeInUp" data-wow-delay="0.3s">
                            <p className="text-center mb-4"></p>
                            <form onSubmit={handlesubmit}>
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="text" className="form-control" id="name" required value={name} onChange={(e)=>setName(e.target.value)} placeholder="Your Name"/>
                                            <label htmlFor="name">Your Name</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating">
                                            <input type="email" className="form-control" required id="email" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Your Email"/>
                                            <label htmlFor="email">Your Email</label>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-floating">
                                            <input type="number" className="form-control" required id="phone" value={phone} onChange={(e)=>setPhone(e.target.value)} placeholder="Mobile No"/>
                                            <label htmlFor="Phone">Mobile No</label>
                                        </div>
                                        </div>
                                    <div className="col-6">
                                        <div className="form-floating">
                                            <input type="text" className="form-control" required id="subject" value={subject} onChange={(e)=>setSubject(e.target.value)} placeholder="Subject"/>
                                            <label htmlFor="subject">Subject</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-floating">
                                            <textarea className="form-control" required placeholder="Leave a message here" value={message} onChange={(e)=>setMessage(e.target.value)} id="message" style={{height: "150px"}}></textarea>
                                            <label htmlFor="message">Message</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button className="btn btn-success w-100 py-3"  type="submit">Send Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      


        <div className="mapouter"><div className="gmap_canvas"><iframe className="gmap_iframe" width="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=600&amp;height=480&amp;hl=en&amp;q=H - 161, BSI Park, Noida 63, Noida 301201&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></div></div>


        <Footer/>
    </div>


  )
}

export default Contact
