import React from 'react'
import Footer from './Footer'
import Header from './Header'

const FacebookAdvertising = () => {
  return (
    <>
    <div className="container-xxl bg-white p-0">
   
    

   
    <div className="container-xxl position-relative p-0">
        <Header/>

        <div className="container-xxl py-5 bg-danger hero-header">
            <div className="container my-5 py-5 px-lg-5">
                <div className="row g-5 py-5">
                    <div className="col-12 text-center">
                        <h1 className="text-white animated slideInDown">Facebook Advertising</h1>
                        <hr className="bg-white mx-auto mt-0" style={{width: "90px"}}/>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><a className="text-white" href='/'>Home</a></li>
                                <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                                <li className="breadcrumb-item text-white active" aria-current="page">Facebook Advertising</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div className="container-xxl py-5">
        <div className="container py-5 px-lg-5">
        <div className="row g-4">
        <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
            <div className=" service-item rounded text-center p-4 mymove" style={{
                backgroundColor: "coral",                
                animation: "mymove 5s infinite"}}>
                <i className="fa fa-3x fa-mail-bulk text-secondary mb-4"></i>
                <h5 className="mb-3">Develop and Optimize Ads</h5>
                <p className="m-0">The team designs the overall structure of the ads. After approval, build successful advertisements and keep improving for optimum results. Additionally, the team also checks ad variants to distinguish the highest oerforming ads.Reporting and improving paid social ads</p>
            </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="service-item  rounded text-center p-4 mymove" style={{
                backgroundColor: "coral",                
                animation: "mymove 5s infinite"}}>
                <i className="fa fa-3x fa-search text-secondary mb-4"></i>
                <h5 className="mb-3">Monitoring and Analyzing</h5>
                <p className="m-0">Our team handles and monitors Facebook ads on a regular basis by keeping in mind the ad budget. Our experts analyze the results data and make adjustments to the campaign in order to boost performance over time. Our company manages and tweaks the ad parameters for each campaign, monitoring engagement and interaction from customer base</p>
            </div>
        </div>
        <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
            <div className="service-item rounded text-center p-4 mymove" style={{
                backgroundColor: "coral",                
                animation: "mymove 5s infinite"}}>
                <i className="fa fa-3x fa-laptop-code text-secondary mb-4"></i>
                <h5 className="mb-3">Reporting</h5>
                <p className="m-0">Our company presents a detailed report each month to highlight the overall performance of Facebook ad campaign. Also, for the measurement of performance, the team has tools to find out the actual number of leads the website has got in a particular time period.</p>
            </div>
        </div>
    </div>
        </div>
    </div>





    <div className='container'>
    <article className="entry">
   

    <h2 className="entry-title py-4">
       Facebook Advertising
    </h2>

    {/* <div className="entry-meta">
    <ul>
      <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">John Doe</a></li>
      <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jan 1, 2020</time></a></li>
      <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
    </ul>
  </div> */}
    <br />
    <div className="entry-content">
      <p style={{ textAlign: "justify" }}>
     
Advertisements on Facebook are one of the most efficient ways get leads online. More than 2.3 billion active users use this eminent social media platform, with spending a couple of hours each week. Businesses who effectively utilize Facebook ads can expand their business exponentially in a stable and significant manner.Our company Facebook advertising team takes care of clients to reach and sell directly to the target audience.
      </p>
      
    </div>
  </article>
  </div>


  




    <Footer/>
    </div>
    </>
  )
}

export default FacebookAdvertising
