import React from 'react'
import Footer from './Footer'
import Header from './Header'

const LinkedinAdvertising = () => {
  return (
    <>
    <div className="container-xxl bg-white p-0">  
    <div className="container-xxl position-relative p-0">
        <Header/>

        <div className="container-xxl py-5 bg-danger hero-header">
            <div className="container my-5 py-5 px-lg-5">
                <div className="row g-5 py-5">
                    <div className="col-12 text-center">
                        <h1 className="text-white animated slideInDown">Linkedin Advertising</h1>
                        <hr className="bg-white mx-auto mt-0" style={{width: "90px"}}/>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><a className="text-white" href='/'>Home</a></li>
                                <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                                <li className="breadcrumb-item text-white active" aria-current="page">Linkedin Advertising</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div className="container-xxl py-5">
        <div className="container py-5 px-lg-5">
        <div className="row g-4">
        <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
            <div className=" service-item rounded text-center p-4 mymove" style={{
                backgroundColor: "coral",                
                animation: "mymove 5s infinite"}}>
                <i className="fa fa-3x fa-mail-bulk text-secondary mb-4"></i>
                <h5 className="mb-3">Develop and Optimize Ads</h5>
                <p className="m-0">
The process starts from developing a customized advertising strategy to identify the potential ads to feature on LinkedIn. The team optimizes the ads by creating interaction with the audience which further motivates the users to stay engaged with the brand, this in turn, gives an opportunity to convert the traffic and generate leads.</p>
            </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="service-item  rounded text-center p-4 mymove" style={{
                backgroundColor: "coral",                
                animation: "mymove 5s infinite"}}>
                <i className="fa fa-3x fa-search text-secondary mb-4"></i>
                <h5 className="mb-3">Monitoring and Analyzing</h5>
                <p className="m-0">
The team analyzes the advertisements on a weekly and monthly basis in order to track the campaign’s success and tweak the ad strategy as per audience feedback. Frequent monitoring also helps in improving the efficiency in future ads which further benefits in gathering large audience base with best conversion rate.</p>
            </div>
        </div>
        <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
            <div className="service-item rounded text-center p-4 mymove" style={{
                backgroundColor: "coral",                
                animation: "mymove 5s infinite"}}>
                <i className="fa fa-3x fa-laptop-code text-secondary mb-4"></i>
                <h5 className="mb-3">Reporting</h5>
                <p className="m-0">
                
                As a responsible agency, we present a detailed report each month/week to highlight the overall performance of the status of LinkedIn ad campaign. The reports are helpful in calculating the ROI on LinkedIn ads.</p>
            </div>
        </div>
    </div>
        </div>
    </div>
    <div className='container'>
    <article className="entry">
    <h2 className="entry-title py-4">
    Linkedin Advertising
    </h2>
    <br />
    <div className="entry-content">
      <p style={{ textAlign: "justify" }}>   
      
      LinkedIn is loaded with business professionals. Due to its professional aspect, the advertisements on LinkedIn are likely to be taken sincerely by audience than the ads on other platform. Its advertising features offers to destine the audience specifically by their location and work profile. It also gives access to the contacts of recent site visitors and e-mail contacts of an account.
      <br/>
      <br/>
      Our company as a LinkedIn advertising agency is authorized to develop and run ads on LinkedIn. We have a highly specialized team of professionals to plan and execute successful LinkedIn advertising campaigns to generate leads. A successful LinkedIn ad provides with more site traffic, more page follows, authentic leads, and better brand recognition.  
      
      </p>
     </div>
  </article>
  </div>
    <Footer/>
    </div>
    </>
  )
}

export default LinkedinAdvertising
