import React from 'react'
import Footer from './Footer'
import Header from './Header'

const TwitterAdvertising = () => {
  return (
    <>
    <div className="container-xxl bg-white p-0">  
    <div className="container-xxl position-relative p-0">
        <Header/>

        <div className="container-xxl py-5 bg-danger hero-header">
            <div className="container my-5 py-5 px-lg-5">
                <div className="row g-5 py-5">
                    <div className="col-12 text-center">
                        <h1 className="text-white animated slideInDown">Twitter Advertising</h1>
                        <hr className="bg-white mx-auto mt-0" style={{width: "90px"}}/>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><a className="text-white" href='/'>Home</a></li>
                                <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                                <li className="breadcrumb-item text-white active" aria-current="page">Twitter Advertising</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div className="container-xxl py-5">
        <div className="container py-5 px-lg-5">
        <div className="row g-4">
        <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
            <div className=" service-item rounded text-center p-4 mymove" style={{
                backgroundColor: "coral",                
                animation: "mymove 5s infinite"}}>
                <i className="fa fa-3x fa-mail-bulk text-secondary mb-4"></i>
                <h5 className="mb-3">Develop and Optimize Ads</h5>
                <p className="m-0">
            Our company makes a Twitter advertisement calendar ready to influence the purchase behavior of target audience. Our specialists create multiple versions of advertisements for the brand and optimize them with using hangtags. Optimization of ads includes Twitter Business Outlook, a better source for blogs and articles to advertise more and to reach potential audience and more followers.</p>
            </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="service-item  rounded text-center p-4 mymove" style={{
                backgroundColor: "coral",                
                animation: "mymove 5s infinite"}}>
                <i className="fa fa-3x fa-search text-secondary mb-4"></i>
                <h5 className="mb-3">Monitoring and Analyzing</h5>
                <p className="m-0">
                The team analyzes the advertisements on a weekly and monthly basis. The tream monitors the progression of the ads and stops the ads that don’t work, monitors the development of followers’ base to see interaction of audience in each tweet. Furthermore, evaluate the campaign's success data for making changes to improve efficiency. Last point includes the utilization of these ads by targeting more audiences.</p>
            </div>
        </div>
        <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
            <div className="service-item rounded text-center p-4 mymove" style={{
                backgroundColor: "coral",                
                animation: "mymove 5s infinite"}}>
                <i className="fa fa-3x fa-laptop-code text-secondary mb-4"></i>
                <h5 className="mb-3">Reporting</h5>
                <p className="m-0">
                
               Our company presents a detailed report each month/week to highlight the overall performance of the ads to monitor the status of the ad campaigns. These reports provide a complete overview of the Twitter ad campaign and the performance.</p>
            </div>
        </div>
    </div>
        </div>
    </div>
    <div className='container'>
    <article className="entry">
    <h2 className="entry-title py-4">
    Twitter Advertising
    </h2>
    <br />
    <div className="entry-content">
      <p style={{ textAlign: "justify" }}>   
      
      Twitter has more than 126 million users a day, presenting companies an opportunity to communicate with a wide pool of people. Branding of a product or service is one of the biggest advantages for a business of using Twitter advertisements.
      <br/>
      <br/>
Our company renders a seamless experience to its clients in Twitter advertisement by driving awareness about the brand, increasing customer base by sharing the content among targeted audience. The expert team of Twitter advertisements handles everything from planning the campaign with twitter agency playbook to launching a video or pictorial campaign. A well targeted ad on Twitter will you more Twitter followers, more retweets, organic website traffic, more reach and impressions.     
      </p>
     </div>
  </article>
  </div>
    <Footer/>
    </div>
    </>
  )
}

export default TwitterAdvertising
