import React from 'react'
import Footer from './Footer'
import Header from './Header'

const WebDevelopment = () => {
  return (
    <>
    <div className="container-xxl bg-white p-0">
    <div className="container-xxl position-relative p-0">
      <Header />

      <div className="container-xxl py-5 bg-danger hero-header">
        <div className="container my-5 py-5 px-lg-5">
          <div className="row g-5 py-5">
            <div className="col-12 text-center">
              <h1 className="text-white animated slideInDown">Web Development</h1>
              <hr
                className="bg-white mx-auto mt-0"
                style={{ width: "90px" }}
              />
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a className="text-white" href="/">
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a className="text-white" href="#">
                      Pages
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Web Development
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>


<div className='container'>
    <article className="entry">
    <div className="entry-img">
      <img
        src="img/webd.png"
        width="100%"
        alt=""
        className="img-fluid"
      />
    </div>

    <h2 className="entry-title py-4">
      <a href="https://en.wikipedia.org/wiki/Web_development">Design & Development</a>
    </h2>

    {/* <div className="entry-meta">
    <ul>
      <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">John Doe</a></li>
      <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jan 1, 2020</time></a></li>
      <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
    </ul>
  </div> */}
    <br />
    <div className="entry-content">
      <p style={{ textAlign: "justify" }}>
        We provide you complete assistance to come up with
        breakthrough software in this competitive world. We offer
        web development and designing amenities for your networking,
        marketing, communication and business needs with support of
        our highly qualified team of professionals with innovative
        ideas, passion and dedication for your web based solutions,
        surprising the marketplace.
        <br />
        <br />
        Make your business shine online with a custom development
        website designed just for you by a professional designer.
        Need ideas? We’ve collected some amazing examples of
        development websites from our global community of designers.
        Get inspired and start planning the perfect development web
        design today.
      </p>
      <div className="read-more">
        <a href="https://en.wikipedia.org/wiki/Web_development" type="button" className="btn btn-info">
          Read More
        </a>
      </div>
    </div>
  </article>
  </div>


    <Footer/>
    </div>
    </>
  )
}

export default WebDevelopment
