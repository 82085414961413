import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from '../vendors/images/B.jpg';
import second from '../vendors/images/A.jpg';
import third from '../vendors/images/C.jpg';
import '../Components/Insta.css'
import Header from './Header';
import Footer from './Footer';

function Insta() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>    
    <div className="container-xxl position-relative p-0">
       <Header/>

       <div className="container-xxl py-5 bg-danger hero-header">
           <div className="container my-5 py-5 px-lg-5">
               <div className="roww g-5 py-5">
                   <div className="col-12 text-center">
                       <h1 className="text-white animated slideInDown">Instagram</h1>
                       <hr className="bg-white mx-auto mt-0" style={{width: "90px"}}/>
                       <nav aria-label="breadcrumb">
                           <ol className="breadcrumb justify-content-center">
                               <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                               <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                               <li className="breadcrumb-item text-white active" aria-current="page">Instagram</li>
                           </ol>
                       </nav>
                   </div>
               </div>
           </div>
       </div>
   </div>
 

    <div class="headerr">
    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
      <iframe width="1000" height="600" src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1">
      </iframe>
     
        <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <iframe width="1000" height="600" src="https://www.youtube.com/embed/yTQGL7i9oHc?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <iframe width="1000" height="600" src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1">
      </iframe>
        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>


    <h1>Instagram Channel</h1>
    <p>This Channel most popular in social media</p>
  </div>
  
  <div class="navbarr">
    <a href="#">Link</a>
    <a href="#">Link</a>
    <a href="#">Link</a>
    <a href="#" class="right">Link</a>
  </div>
  
  <div class="roww">
    <div class="side">
      <h2>About Me</h2>
      <h5>Photo of me:</h5>
      <iframe width="100%" height="200" src="https://www.youtube.com/embed/yTQGL7i9oHc?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <p>Some text about me in culpa qui officia deserunt mollit anim..</p>
      <h3>More Text</h3>
      <p>Lorem ipsum dolor sit ame.</p>
      <div class="fakeimg" style={{height:"60px"}}>Image</div><br/>
      <div class="fakeimg" style={{height:"60px"}}>Image</div><br/>
      <div class="fakeimg" style={{height:"60px"}}>Image</div>
    </div>
    <div class="main">
      <h2>TITLE HEADING</h2>
      <h5>Title description, Dec 7, 2017</h5>
      <iframe width="100%" height="500" src="https://www.youtube.com/embed/yTQGL7i9oHc?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <p>Some text..</p>
      <p>Sunt in culpa qui officia deserunt mollit anim id est laborum consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
      <br/>
      <h2>TITLE HEADING</h2>
      <h5>Title description, Sep 2, 2017</h5>
      <img src={ExampleCarouselImage} alt='' class="fakeimg" style={{height:"200px"}}></img>
      <p>Some text..</p>
      <p>Sunt in culpa qui officia deserunt mollit anim id est laborum consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
    </div>
  </div>
  
  <div class="footerr">
    <h2>Footer</h2>
  </div>
  <Footer/>

          </>

  );
}

export default Insta;