import logo from './logo.svg';
import './App.css';
import React from 'react'
import { BrowserRouter, Route,Switch,Routes } from 'react-router-dom';
import Contact from './Components/Contact';
import Home from './Components/Home';
import Service from './Components/Service';
import About from './Components/About';
import Project from './Components/Project';
import NotFountPage from './Components/NotFountPage';
import Login from './Components/Login';
import FAQ from './Components/FAQ';
import Insta from './Components/Insta';
// import '../src/vendors/styles/core.css'
import '../src/vendors/styles/icon-font.min.css'
import '../src/vendors/styles/style.css'

import Dashboard from './Components/Dashboard';
import Features from './Components/Features';
import PrivateRoute from './Components/PrivateRoute';
import SEO from './Components/SEO';
import WebDevelopment from './Components/WebDevelopment';
import Socialmedia from './Components/Socialmedia';
import EmailMarketing from './Components/EmailMarketing';
import PPC_Advertising from './Components/PPC_Advertising';
import AppDevelopment from './Components/AppDevelopment';
import Brand_Logo from './Components/Brand_Logo';
import GraphicDeginer from './Components/GraphicDeginer';
import ContentMarketing from './Components/Contentmarketing';
import Lead_generation from './Components/Lead_generation';
import FacebookAdvertising from './Components/FacebookAdvertising';
import InstagramAdvertising from './Components/InstagramAdvertising';
import LinkedinAdvertising from './Components/LinkedinAdvertising';
import TwitterAdvertising from './Components/TwitterAdvertising';
import YoutubeAdvertising from './Components/YoutubeAdvertising';
// import '../src/plugins/datatables/css/dataTables.bootstrap4.min.css'
// import '../src/plugins/datatables/css/responsive.bootstrap4.min.css'

// import Dashboard from './Components/Dashboard';





function App() {
  
  return (
    <BrowserRouter>
    <div className="App">
    <Routes>
    <Route path='/Login' element={<Login></Login>}/>
    <Route path='/' element={<Home></Home>}/>
    <Route path='/Admin' element={<PrivateRoute Component={Dashboard}/>}>
    {/* <Route path='Admin' element={ <Dashboard />}/> */}
    </Route>    
    <Route path='/About' element={<About></About>}/>
    <Route path='/Service' element={<Service></Service>}/>
    <Route path='/Contact' element={<Contact></Contact>}/>
    <Route path='/Project' element={<Project></Project>}/>
    <Route path='/Features' element={<Features></Features>}/>
    <Route path='/Insta' element={<Insta></Insta>}/>
    <Route path='/FAQ' element={<FAQ></FAQ>}/>
    <Route path='/Seo' element={<SEO></SEO>}/>
    <Route path='/Socialmedia' element={<Socialmedia></Socialmedia>}/>
    <Route path='/EmailMarketing' element={<EmailMarketing></EmailMarketing>}/>
    <Route path='/Web_development' element={<WebDevelopment/>}/>
    <Route path='/PPC_Advertising' element={<PPC_Advertising/>}/>
    <Route path='/App_Development' element={<AppDevelopment/>}/>
    <Route path='/Brand_Logo' element={<Brand_Logo/>}/>
    <Route path='/GraphicDesign' element={<GraphicDeginer/>}/>   
    <Route path='/Content_marketing' element={<ContentMarketing/>}/>   
    <Route path='/Lead_generation' element={<Lead_generation/>}/>   
    <Route path='/Facebook_Advertising' element={<FacebookAdvertising/>}/>   
    <Route path='/Instagram_Advertising' element={<InstagramAdvertising/>}/>   
    <Route path='/Linkedin_Advertising' element={<LinkedinAdvertising/>}/>   
    <Route path='/Twitter_Advertising' element={<TwitterAdvertising/>}/>  
    <Route path='/Youtube_Advertising' element={<YoutubeAdvertising/>}/>   
     
    <Route path='*' element={<NotFountPage></NotFountPage>}/>
    </Routes>
    
    </div>
    </BrowserRouter>
  );
}

export default App;
