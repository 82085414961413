import React from 'react'
import Footer from './Footer'
import Header from './Header'

const SEO = () => {
  return (
    <>
    <div className="container-xxl bg-white p-0">
    <div className="container-xxl position-relative p-0">
      <Header />

      <div className="container-xxl py-5 bg-danger hero-header">
        <div className="container my-5 py-5 px-lg-5">
          <div className="row g-5 py-5">
            <div className="col-12 text-center">
              <h1 className="text-white animated slideInDown">SEO</h1>
              <hr
                className="bg-white mx-auto mt-0"
                style={{ width: "90px" }}
              />
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a className="text-white" href="/">
                      Home
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a className="text-white" href="#">
                      Pages
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Seo
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

<div className='container'>
    <article className="entry">
    <div className="entry-img">
      <img
        src="img/SEO.jpg"
        alt=""
        width="100%"
        className="img-fluid"
      />
    </div>

    <h2 className="entry-title py-4">
      <a href="https://www.wordstream.com/seo">SEO Optimization</a>
    </h2>

    {/* <div className="entry-meta">
    <ul>
      <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">John Doe</a></li>
      <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jan 1, 2020</time></a></li>
      <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
    </ul>
  </div> */}
    <br />
    <div className="entry-content">
      <p style={{ textAlign: "justify" }}>
        SEO stands for “search engine optimization.” In simple
        terms, SEO means the process of improving your website to
        increase its visibility in Google, Microsoft Bing, and other
        search engines whenever people search for: Products you
        sell. Services you provide. Information on topics in which
        you have deep expertise and/or experience. The better
        visibility your pages have in search results, the more
        likely you are to be found and clicked on. Ultimately, the
        goal of search engine optimization is to help attract
        website visitors who will become customers, clients or an
        audience that keeps coming back.
        <br />
        <br />
        SEO is a critical marketing channel. First, and foremost:
        organic search delivers 53% of all website traffic. That’s
        one big reason why the global SEO industry is forecast to
        reach a staggering $122.11 billion by 2028. SEO drives real
        business results for brands, businesses and organizations of
        all sizes.
      </p>
      <div className="read-more">
        <a href="https://www.wordstream.com/seo"  className="btn btn-info">
          Read More
        </a>
      </div>
    </div>
  </article>
  </div>
  

    <Footer/>
    </div>
    </>
  )
}

export default SEO
