import React from 'react'
import Footer from './Footer'
import Header from './Header'

const Lead_generation = () => {
  return (
    <>
    <div className="container-xxl bg-white p-0">
   
    

   
    <div className="container-xxl position-relative p-0">
        <Header/>

        <div className="container-xxl py-5 bg-danger hero-header">
            <div className="container my-5 py-5 px-lg-5">
                <div className="row g-5 py-5">
                    <div className="col-12 text-center">
                        <h1 className="text-white animated slideInDown">Lead Generation</h1>
                        <hr className="bg-white mx-auto mt-0" style={{width: "90px"}}/>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><a className="text-white" href='/'>Home</a></li>
                                <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                                <li className="breadcrumb-item text-white active" aria-current="page">Lead Generation</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>



    





    <div className='container'>
    <article className="entry">
   

    <h2 className="entry-title py-4">
      Lead Generation Strategy
    </h2>

    {/* <div className="entry-meta">
    <ul>
      <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">John Doe</a></li>
      <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jan 1, 2020</time></a></li>
      <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
    </ul>
  </div> */}
    <br />
    <div className="entry-content">
      <p style={{ textAlign: "justify" }}>
      
      Lead generation is an essential part while doing digital marketing for your products and services. An effective lead generation will help in converting the leads into your future loyal customers. our company is the leading digital marketing company, and we are aware of the importance of lead generation for your business. We create tailored lead generation strategies for our clients based on their business strategies and core values.    </p>
      
    </div>
  </article>
  </div>


  
  <div className='container'>
  <article className="entry">
 

  <h2 className="entry-title py-4">
  Lead Generation Process
  </h2>

  {/* <div className="entry-meta">
  <ul>
    <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">John Doe</a></li>
    <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jan 1, 2020</time></a></li>
    <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
  </ul>
</div> */}
  <br />
  <div className="entry-content">
    <p style={{ textAlign: "justify" }}>
    Our team has a detailed discussion with the client and understand their business and their expectations. Post that we develop a strategy to improve the site rankings using keywords and phrases that will aid in making your website appear on the first page of the search engine itself. Various steps like keyword selection, navigation, website design and optimization are taken into consideration while developing the SEO Strategy for your business.
    <br />
    <br />
Needless to say, Social media is one of the most effective platforms for lead generation. Our team of expert create contents for various social media platform and share those content in the respective platforms. This will help in starting an engagement with the target audience that will help you to reach further and attract more leads towards your website.
<br />
<br />
Content Marketing is another useful tool through which our company helps the client in lead generation. Engaging content will ensure that the visitor spends more time on your website and help in creating a positive impact on your overall website ranking, thus making your website appear in the first page of the search results.
<br />
<br />
Pay-per-click (PPC) advertising is one of the most sought out ways in digital marketing for lead generation. our company helps in utilising the effective keywords that would help in triggering your ads in appearing in the search results. More than half of the PPC visitors are more undoubtedly to make a purchase from your website than those from organic searches.
   
    

    </p>
    
  </div>
  <h1>Start Your Project Today!
  </h1>
  <p> Start grow your business with us.</p>
</article>
</div>



    <Footer/>
    </div>
    </>
  )
}

export default Lead_generation
