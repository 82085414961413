import React from 'react'
import Footer from './Footer'
import Header from './Header'

const GraphicDeginer = () => {
  return (
    <>
    <div className="container-xxl bg-white p-0">
   
    

   
    <div className="container-xxl position-relative p-0">
        <Header/>

        <div className="container-xxl py-5 bg-danger hero-header">
            <div className="container my-5 py-5 px-lg-5">
                <div className="row g-5 py-5">
                    <div className="col-12 text-center">
                        <h1 className="text-white animated slideInDown">Graphic Design</h1>
                        <hr className="bg-white mx-auto mt-0" style={{width: "90px"}}/>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><a className="text-white" href='/'>Home</a></li>
                                <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                                <li className="breadcrumb-item text-white active" aria-current="page">Graphic Design</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>



    





    <div className='container'>
    <article className="entry">
   

    <h2 className="entry-title py-4">
     Graphic Design Service
    </h2>

    {/* <div className="entry-meta">
    <ul>
      <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">John Doe</a></li>
      <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jan 1, 2020</time></a></li>
      <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
    </ul>
  </div> */}
    <br />
    <div className="entry-content">
      <p style={{ textAlign: "justify" }}>
      
Get a gamut of graphic design service that covers every possible area of the communication design. Be it responsive websites, stunning adverts, eye-catching flyers, posters that pop, animated social media messages, and lively lightweight digital advertising campaigns – the team Our team can do it all. A team that believes in creating designs that delivers ideas and sends a message.
<br/>
<br/>

The layout, design, colors & font all speak for the brand. A design is good and a creative matters only when it connects.
      </p>
      
    </div>
  </article>
  </div>


  
  <div className='container'>
  <article className="entry">
 

  <h2 className="entry-title py-4">
    How We Work
  </h2>

  {/* <div className="entry-meta">
  <ul>
    <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">John Doe</a></li>
    <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jan 1, 2020</time></a></li>
    <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
  </ul>
</div> */}
  <br />
  <div className="entry-content">
    <p style={{ textAlign: "justify" }}>
    Our company houses a team of proficient graphic designers who can cater to every business need. Be it website graphics design or infographics, business cards or presentation brochures, brochures, daily social media posts, packaging designs, annual reports, web banners, advertorial designs. We guarantee you professional experience with unique designs that are sure to help your goals.
    <br />
    <br />
    Before the designing begins Our starts with a brief questionnaire, which enables the designer understand your specific needs. Once the details are clear the designers gets an in-depth understanding of your concept and goals, which help the team develop the framework to get things done with perfection.
    <br />
    <br />
    Experienced team of designers in Our compnay process and brainstorm ideas to make sure that the final draft comes out exactly the way client wants. In case of any question, the client can always contact our team leader or even the designer during the design process.
    <br />
      <br />
    Then comes the revision process which includes color, font and other design modifications so that the initial draft can be tweaked in accordance with client feedback and recommendations. Here the graphic designer also provides insights just to make sure that the client is fully satisfied.
    <br />
      <br />
    Once you are fully satisfied we send you the final artwork via email in the following file formats: ai, pdf, eps, png, jpeg and psd.We will transfer full copyright on the agreed final designs to the client and have no legal right to use it without the clients’ consent.

    </p>
    
  </div>
</article>
</div>



    <Footer/>
    </div>
    </>
  )
}

export default GraphicDeginer
