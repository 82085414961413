import React, { useState } from "react";
import { Link } from "react-router-dom";
import Getstarted from "./Getstarted";
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
        <Link to="/" className="navbar-brand p-0">
          <h1 className="m-0">DGital</h1>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="fa fa-bars"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav mx-auto py-0">
            <Link to="/" className="nav-item nav-link">
              Home
            </Link>

            <div className="nav-item dropdown">
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Design
              </Link>
              <div className="dropdown-menu m-0">
                <Link to="/Brand_Logo" className="dropdown-item">
                  Brand & Logo
                </Link>
                <Link to="/GraphicDesign" className="dropdown-item">
                  Graphic Design
                </Link>
              </div>
            </div>

            <div className="nav-item dropdown">
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                SEO
              </Link>
              <div className="dropdown-menu m-0">
              <Link to="/Seo" className="dropdown-item">
                  Search Engine Optimization
                </Link>
                <Link to="/Content_marketing" className="dropdown-item">
                  Content Marketing
                </Link>

                <Link to="/Lead_generation" className="dropdown-item">
                  Lead Generation
                </Link>
              </div>
            </div>

            <div className="nav-item dropdown">
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Social Media
              </Link>
              <div className="dropdown-menu m-0">
                
                <Link to="/Facebook_Advertising" className="dropdown-item">
                  Facebook Advertisement
                </Link>
                <Link to="/Instagram_Advertising" className="dropdown-item">
                  Instagram Advertisement
                </Link>
                <Link to="/Linkedin_Advertising" className="dropdown-item">
                  Linkedin Advertisement
                </Link> 
                 <Link to="/Twitter_Advertising" className="dropdown-item">
                  Twitter Advertisement
                </Link>  
                <Link to="/Youtube_Advertising" className="dropdown-item">
                  Youtube Advertisement
                </Link>    
                            
              </div>
            </div>

            <div className="nav-item dropdown">
              <Link
                to="/Service"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Service
              </Link>
              <div className="dropdown-menu m-0">
                                
               
                <Link to="/EmailMarketing" className="dropdown-item">
                  Email Marketing
                </Link>
                <Link to="/Web_development" className="dropdown-item">
                  Web Development
                </Link>
                <Link to="/PPC_Advertising" className="dropdown-item">
                  PPC Advertising
                </Link>
                <Link to="/App_Development" className="dropdown-item">
                  App Development
                </Link>
              </div>
            </div>
           
            <div className="nav-item dropdown">
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                Pages
              </Link>
              <div className="dropdown-menu m-0">
                <Link to="/login" className="dropdown-item">
                  Admin
                </Link>
                <Link to="/Contact" className="dropdown-item">
                  Contact Us
                </Link>
                <Link to="/About" className="dropdown-item">
                  About
                </Link>
                <Link to="/404" className="dropdown-item">
                  404 Page
                </Link>
               {/* <Link to="/Insta" className="dropdown-item">
                  Instagram
                </Link>
                <Link to="/Project" className="dropdown-item">
                Project
              </Link>
              <Link to="/features" className="dropdown-item">
                  Features
  </Link> */}
              </div>
            </div>
            
           
          </div>
          <p style={{ color: "white", marginTop: "20px" }}>
            <i className="fa fa-phone alt me-3"></i>
            <a style={{ color: "white" }} href="tel:8077115322">
              Call +91 8077115322
            </a>
          </p>
          <button
            onClick={togglePopup}
            className="btn rounded-pill py-2 px-4 ms-3 d-none d-lg-block"
          >
            Get Started
          </button>
          {isOpen && <Getstarted handleClose={togglePopup} />}{" "}
        </div>
      </nav>
    </>
  );
};

export default Header;
