import React from 'react'
import Header from './Header'
import Footer from './Footer'

function ContentMarketing() {
  return (
    <><div className="container-xxl bg-white p-0">
   
    

   
    <div className="container-xxl position-relative p-0">
        <Header/>

        <div className="container-xxl py-5 bg-danger hero-header">
            <div className="container my-5 py-5 px-lg-5">
                <div className="row g-5 py-5">
                    <div className="col-12 text-center">
                        <h1 className="text-white animated slideInDown">Content Marketing</h1>
                        <hr className="bg-white mx-auto mt-0" style={{width: "90px"}}/>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><a className="text-white" href='/'>Home</a></li>
                                <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                                <li className="breadcrumb-item text-white active" aria-current="page">COntent Marketing</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div className="container-xxl py-5">    
        <div className="container py-5 px-lg-5">
        <h1 className='py-4'>At Our company We Offer You A Complete Gamut Of Content Marketing Strategy.</h1>
        <div className="row g-4">
        <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
            <div className=" service-item rounded text-center p-4 mymove" style={{                                
                animation: "mymove 5s infinite"}}>
                <i className="fa fa-3x fa-mail-bulk text-secondary mb-4"></i>
                <h5 className="mb-3">Content Creation Services</h5>
                <p className="m-0">We create content that matters. People love stories and good stories always engage. Improve your brand visible with high-quality content for your website, social media channels, email campaigns, and paid distribution. Publishing variety of content in different formats always helps in attracting new audiences.</p>
            </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="service-item  rounded text-center p-4 mymove" style={{                                
                animation: "mymove 5s infinite"}}>
                <i className="fa fa-3x fa-search text-secondary mb-4"></i>
                <h5 className="mb-3">Content Marketing Strategy</h5>
                <p className="m-0">Before creating content we always ask when and where it will be published, how will it be published and who is the target audience? We offer tailored content marketing strategy that begins and ends with your goals. We strategize content marketing paths after an in-depth understanding of who you are as a business and how you wish to express your core values.</p>
            </div>
        </div>
        <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
            <div className="service-item rounded text-center p-4 mymove" style={{                                
                animation: "mymove 5s infinite"}}>
                <i className="fa fa-3x fa-laptop-code text-secondary mb-4"></i>
                <h5 className="mb-3">Promotion & Distribution</h5>
                <p className="m-0">Just creating great content does not help, the content needs to reach the right audience on right time. We help appropriately distribute content beyond your website — through social media, blog channels, pay per click campaigns, and many other channels.</p>
            </div>
        </div>
    </div>
        </div>
    </div>





    <div className='container'>
    <article className="entry">
   

    <h2 className="entry-title py-4">
      Content Marketing Helps You To Reach Out To Customers And Retain And Convert Target Audience Into Potential Clients.
    </h2>

    {/* <div className="entry-meta">
    <ul>
      <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">John Doe</a></li>
      <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jan 1, 2020</time></a></li>
      <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
    </ul>
  </div> */}
    <br />
    <div className="entry-content">
      <p style={{ textAlign: "justify" }}>
      Content Marketing builds communities and motivates engagement via creation and sharing of high quality, problem-solving information that satisfies business objectives. It’s about delivering the right content to the right people at the right time. Our company Content marketing focuses on strategies and execution. It involves actual creation, curation, and editing of content that’s explicitly created for the purposes of marketing. It could be anything from blog posts, to infographics, to social media writing, and is designed at building a trusted link between your company’s products or services and the target customer. Correct content marketing strategy influences consumer behavior, with the end goal of boosting sales and increasing interaction between the brand and customer. Content marketing strategy creates and distributes content that people not only want to consume, but also helps the sales funnel.

      
      </p>
      
    </div>
  </article>
  </div>



    <Footer/>
    </div>

    </>
  )
}

export default ContentMarketing
