import React from 'react'
import deskapp from '../vendors/images/deskapp-logo.svg'
import login from '../vendors/images/login-page-img.png'
import briefcase from '../vendors/images/briefcase.svg'
import person from '../vendors/images/person.svg'
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react'
import { useEffect } from 'react'

const Login = () => {
	const history =useNavigate();
	const [name,setName]=useState('');
	const [pwd,setPwd]=useState('');
	const handlesubmit=(e)=>{
		e.preventDefault()
		
		if(name==="jd" && pwd==="123"){
			history('/Admin')
			localStorage.setItem('login',true)
		}
		else{
			alert('Invalid Details');
			history('/Login')
			setName("");
			setPwd("");
		}
	}
	// useEffect(()=>{
	// 	let login = localStorage.getItem('login');
	// 	if(login){
	// 		history('/Private')
	// 	}
	// })
  return (
	<>
	  <div className="login-page">
	<div className="login-header box-shadow">
		<div className="container-fluid d-flex justify-content-between align-items-center">
			<div className="brand-logo">
				<Link to="">
					<img src={deskapp} alt=""/>
				</Link>
			</div>
			<div className="login-menu">
				<ul>
					<li><Link to="/">Home</Link></li>
				</ul>
			</div>
		</div>
	</div>
	<div className="login-wrap d-flex align-items-center flex-wrap justify-content-center">
		<div className="container">
			<div className="row align-items-center">
				<div className="col-md-6 col-lg-7">
					<img src={login} alt=""/>
				</div>
				<div className="col-md-6 col-lg-5">
					<div className="login-box bg-white box-shadow border-radius-10">
						<div className="login-title">
							<h2 className="text-center text-primary">Login To DeskApp</h2>
						</div>
						<form onSubmit={handlesubmit}>
							<div className="select-role">
								<div className="btn-group btn-group-toggle" data-toggle="buttons">
									<label className="btn active">
										<input type="radio" name="options" id="admin"/>
										<div className="icon"><img src={briefcase} className="svg" alt=""/></div>
										<span>I'm</span>
										Manager
									</label>
									<label className="btn">
										<input type="radio" name="options" id="user"/>
										<div className="icon"><img src={person} className="svg" alt=""/></div>
										<span>I'm</span>
										Employee
									</label>
								</div>
							</div>
							<div className="input-group custom">
								<input type="text" className="form-control form-control-lg" onChange={(e)=>setName(e.target.value)} placeholder="Username"/>
								<div className="input-group-append custom">
									<span className="input-group-text"><i className="icon-copy dw dw-user1"></i></span>
								</div>
							</div>
							<div className="input-group custom">
								<input type="password" className="form-control form-control-lg" onChange={(e)=>setPwd(e.target.value)} placeholder="**********"/>
								<div className="input-group-append custom">
									<span className="input-group-text"><i className="dw dw-padlock1"></i></span>
								</div>
							</div>
							{/* <div className="row pb-30">
								<div className="col-6">
									<div className="custom-control custom-checkbox">
										<input type="checkbox" className="custom-control-input" id="customCheck1"/>
										<label className="custom-control-label" for="customCheck1">Remember</label>
									</div>
								</div>
								<div className="col-6">
									<div className="forgot-password"><a href="forgot-password.html">Forgot Password</a></div>
								</div>
							</div> */}
							<div className="row">
								<div className="col-sm-12">
									<div className="input-group mb-0" >
										
											<input className="btn btn-primary btn-lg btn-block" style={{width:'100%'}} type="submit" value="Sign In"/>
										
										{/* <Link className="btn btn-primary btn-lg btn-block" onClick={handlesubmit}    to="/Admin">Sign In</Link> */}
									</div>
									{/* <div className="font-16 weight-600 pt-10 pb-10 text-center" data-color="#707373">OR</div> */}
									{/* <div className="input-group mb-0">
										<a className="btn btn-outline-primary btn-lg btn-block" href="register.html">Register To Create Account</a>
									</div> */}
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	
</div>
	</>
  )
}

export default Login;


