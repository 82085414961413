import React from 'react'
import Footer from './Footer'
import Header from './Header'

const YoutubeAdvertising = () => {
  return (
    <>
    <div className="container-xxl bg-white p-0">  
    <div className="container-xxl position-relative p-0">
        <Header/>

        <div className="container-xxl py-5 bg-danger hero-header">
            <div className="container my-5 py-5 px-lg-5">
                <div className="row g-5 py-5">
                    <div className="col-12 text-center">
                        <h1 className="text-white animated slideInDown">Youtube Advertising</h1>
                        <hr className="bg-white mx-auto mt-0" style={{width: "90px"}}/>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><a className="text-white" href='/'>Home</a></li>
                                <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                                <li className="breadcrumb-item text-white active" aria-current="page">Youtube Advertising</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div className="container-xxl py-5">
        <div className="container py-5 px-lg-5">
        <div className="row g-4">
        <div className="col-lg-4 wow fadeInUp " data-wow-delay="0.1s">
            <div className=" service-item rounded text-center p-4 mymove" style={{
                backgroundColor: "coral",                
                animation: "mymove 5s infinite"}}>
                <i className="fa fa-3x fa-mail-bulk text-secondary mb-4"></i>
                <h5 className="mb-3">Target Audience</h5>
                <p className="m-0">
                
                The youtube Advertising experts ready to drive a campaign to find out the intended audience and carry out engaging Advertising content within a limit of 280 words.</p>
            </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="service-item  rounded text-center p-4 mymove" style={{
                backgroundColor: "coral",                
                animation: "mymove 5s infinite"}}>
                <i className="fa fa-3x fa-search text-secondary mb-4"></i>
                <h5 className="mb-3">Strategy</h5>
                <p className="m-0">
                
                The Advertising team spends sufficient time in identifying the overall business advertising priorities and the usual activities of the target audience on the website and then recommends the best plan, including schedules of promotions, expenditures and expected results. Through a solid experience in youtube Advertising, we know that it takes a light touch to boost a brand on youtube. Therefore, we ensures that the youtube Advertising strategy we create for you, is not only advanced and relevant to your needs, but also adheres to the advertising and Advertising ethical code of the site.</p>
            </div>
        </div>
        <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
            <div className="service-item rounded text-center p-4 mymove" style={{
                backgroundColor: "coral",                
                animation: "mymove 5s infinite"}}>
                <i className="fa fa-3x fa-laptop-code text-secondary mb-4"></i>
                <h5 className="mb-3">Networking and Branding</h5>
                <p className="m-0">
                
                
The networking and branding consider establishing a notable youtube presence for the brand and communication with the main influencers to keep a thriving community. The professionals help in stimulating positive interaction with the followers and make sure that the youtube profile is compatible with the brand specifically with the website. This will give the users a satisfying experience. We also assist with the online tweet promotion to handle the actual discussions.</p>
            </div>
        </div>
    </div>
        </div>
    </div>
    <div className='container'>
    <article className="entry">
    <h2 className="entry-title py-4">
    Youtube Advertising
    </h2>
    <span className='animate-border mr-auto ml-auto mb-4'></span>
    <br />
    <div className="entry-content">
      <p style={{ textAlign: "justify" }}>   
      
      YouTube is now the world's most entertaining advertising platform. The video ads have a vast tendency to reach the most valuable audience. Advertisers are switching from Television advertisements to YouTube advertisements because of its greater ROI. YouTube gives advertisers a unique approach to precisely target the specific group and spend money only when a spectator views the whole pre-roll advert.
      <br/>
      <br/>
      Along with video marketing,Our company special team is trained in YouTube online video advertisement services in an exclusive manner. The team deals with animation, full video, editing and high end advertisement services.

      </p>
     </div>
  </article>
  </div>
    <Footer/>
    </div>
    </>
  )
}

export default YoutubeAdvertising
