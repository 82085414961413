import './Getstarted.css'

import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import uuid from 'react-uuid'; 
// import jj from '../vendors/images/JD2.jpeg'

const Getstarted = (props) => {
    const UniqueId=uuid();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject,setSubject]=useState("");
    const [message,setMessage]=useState("");
    const [phone,setPhone]=useState('');
    const navigate = useNavigate();
    const handlesubmit = (e) => {
      e.preventDefault();
      axios
        .post("https://649ee87e245f077f3e9d1590.mockapi.io/myastrowalk", {
            Id:UniqueId,
          Name: name,
          Email:email,
          Subject: subject,
          Message:message,
          Phone:phone,
        }).then(() => {
            console.log(name, email);
          });
      console.log(name, email);
      alert("Successfully Submit details")
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
      setPhone("");
      navigate('/')
      props.handleClose();

    };

  return (
    <>
    <div className="popup-box" >
      <div className="box" style={{backgroundColor:'skyblue'}}>
      <span className="close-icon" onClick={props.handleClose}>x</span>
    <div className="container contact-form" >
    <div className="contact-image" >
        <img src="https://image.ibb.co/kUagtU/rocket_contact.png" alt="rocket_contact"/>
    </div>
    <form method="post" onSubmit={handlesubmit}>
        <h3>Make a Appointment</h3>
       <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <input type="text" name="txtName" className="form-control" placeholder="Your Name *" required value={name} onChange={(e)=>setName(e.target.value)} />
                </div>
                <div className="form-group">
                    <input type="text" name="txtEmail" className="form-control" placeholder="Your Email *" required value={email} onChange={(e)=>setEmail(e.target.value)} />
                </div>
                <div className="form-group">
                    <input type="text" name="txtPhone" className="form-control" placeholder="Your Phone Number *" required value={phone} onChange={(e)=>setPhone(e.target.value)} />
                </div>
               
                <div className="form-group">
                    <input type="submit" name="btnSubmit" className="btnContact" value="Send Message" />
                </div>
            </div>
            <div className="col-md-6">
            <div className="form-group">
            <input type="text" name="txtsubject" className="form-control" placeholder="Your Subject *" required value={subject} onChange={(e)=>setSubject(e.target.value)} />
        </div>
                <div className="form-group">
                    <textarea name="txtMsg" className="form-control" placeholder="Your Message *" required value={message} onChange={(e)=>setMessage(e.target.value)} style={{width: "100%", height: "150px"}}></textarea>
                </div>
            </div>
        </div>
    </form>
</div>
</div>
</div>
   
    </>
  )
}

export default Getstarted
