import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

function Features() {
  return (
    <>
      <div className="container-xxl position-relative p-0">
        <Header/>

        <div className="container-xxl py-5 bg-danger hero-header">
          <div className="container my-5 py-5 px-lg-5">
            <div className="row g-5 py-5">
              <div className="col-12 text-center">
                <h1 className="text-white animated slideInDown">Features</h1>
                <hr className="bg-white mx-auto mt-0" style={{ width: "90px;" }} />
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb justify-content-center">
                    <li className="breadcrumb-item">
                      <a className="text-white" href="/">
                        Home
                      </a>
                    </li>
                    <li className="breadcrumb-item">
                      <a className="text-white" href="#">
                        Pages
                      </a>
                    </li>
                    <li
                      className="breadcrumb-item text-white active"
                      aria-current="page"
                    >
                      Features
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 
    <Accordion defaultActiveKey="0">
  
      <Accordion.Item eventKey="0">
        <Accordion.Header><h2>Design & Development</h2></Accordion.Header>
        <Accordion.Body><p style={{fontSize:'1rem',textAlign:'justify'}}>
        We provide you complete assistance to come up with breakthrough software in this competitive world. We offer web development and designing amenities for your networking, marketing, communication and business needs with support of our highly qualified team of professionals with innovative ideas, passion and dedication for your web based solutions, surprising the marketplace.<br/>
        Make your business shine online with a custom development website designed just for you by a professional designer. Need ideas? We’ve collected some amazing examples of development websites from our global community of designers. Get inspired and start planning the perfect development web design today.
        </p>
        </Accordion.Body>
      </Accordion.Item>
  

      <Accordion.Item eventKey="1">
        <Accordion.Header><h2>Accordion Item #2</h2></Accordion.Header>
        <Accordion.Body><p style={{fontSize:'1rem',textAlign:'justify'}}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
          </p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
   */}

      {/* -------------------------------- */}

      <section id="blog" className="blog">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-8 entries">
              <article className="entry">
                <div className="entry-img">
                  <img
                    src="img/webd.png"
                    width="100%"
                    alt=""
                    className="img-fluid"
                  />
                </div>

                <h2 className="entry-title py-4">
                  <a href="https://en.wikipedia.org/wiki/Web_development">Design & Development</a>
                </h2>

                {/* <div className="entry-meta">
                <ul>
                  <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">John Doe</a></li>
                  <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jan 1, 2020</time></a></li>
                  <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
                </ul>
              </div> */}
                <br />
                <div className="entry-content">
                  <p style={{ textAlign: "justify" }}>
                    We provide you complete assistance to come up with
                    breakthrough software in this competitive world. We offer
                    web development and designing amenities for your networking,
                    marketing, communication and business needs with support of
                    our highly qualified team of professionals with innovative
                    ideas, passion and dedication for your web based solutions,
                    surprising the marketplace.
                    <br />
                    <br />
                    Make your business shine online with a custom development
                    website designed just for you by a professional designer.
                    Need ideas? We’ve collected some amazing examples of
                    development websites from our global community of designers.
                    Get inspired and start planning the perfect development web
                    design today.
                  </p>
                  <div className="read-more">
                    <button href="https://en.wikipedia.org/wiki/Web_development" type="button" className="btn btn-info">
                      Read More
                    </button>
                  </div>
                </div>
              </article>
              <br />

              <article className="entry">
                <div className="entry-img">
                  <img
                    src="img/SEO.jpg"
                    alt=""
                    width="100%"
                    className="img-fluid"
                  />
                </div>

                <h2 className="entry-title py-4">
                  <a href="https://www.wordstream.com/seo">SEO Optimization</a>
                </h2>

                {/* <div className="entry-meta">
                <ul>
                  <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">John Doe</a></li>
                  <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jan 1, 2020</time></a></li>
                  <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
                </ul>
              </div> */}
                <br />
                <div className="entry-content">
                  <p style={{ textAlign: "justify" }}>
                    SEO stands for “search engine optimization.” In simple
                    terms, SEO means the process of improving your website to
                    increase its visibility in Google, Microsoft Bing, and other
                    search engines whenever people search for: Products you
                    sell. Services you provide. Information on topics in which
                    you have deep expertise and/or experience. The better
                    visibility your pages have in search results, the more
                    likely you are to be found and clicked on. Ultimately, the
                    goal of search engine optimization is to help attract
                    website visitors who will become customers, clients or an
                    audience that keeps coming back.
                    <br />
                    <br />
                    SEO is a critical marketing channel. First, and foremost:
                    organic search delivers 53% of all website traffic. That’s
                    one big reason why the global SEO industry is forecast to
                    reach a staggering $122.11 billion by 2028. SEO drives real
                    business results for brands, businesses and organizations of
                    all sizes.
                  </p>
                  <div className="read-more">
                    <button href="https://www.wordstream.com/seo" type="button" className="btn btn-info">
                      Read More
                    </button>
                  </div>
                </div>
              </article>
              <br />

              <article className="entry">
                <div className="entry-img">
                  <img
                    src="img/socialmedia.jpg"
                    alt=""
                    width="100%"
                    className="img-fluid"
                  />
                </div>

                <h2 className="entry-title py-4">
                  <a href="https://en.wikipedia.org/wiki/Social_media_marketing">Social Media Marketing</a>
                </h2>

                {/* <div className="entry-meta">
                  <ul>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-person"></i>{" "}
                      <a href="blog-single.html">John Doe</a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-clock"></i>{" "}
                      <a href="blog-single.html">
                        <time datetime="2020-01-01">Jan 1, 2020</time>
                      </a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-chat-dots"></i>{" "}
                      <a href="blog-single.html">12 Comments</a>
                    </li>
                  </ul>
                </div> */}
                <br />
                <div className="entry-content">
                  <p style={{ textAlign: "justify" }}>
                    Social media marketing (also known as digital marketing and
                    e-marketing) is the use of social media—the platforms on
                    which users build social networks and share information—to
                    build a company's brand, increase sales, and drive website
                    traffic. In addition to providing companies with a way to
                    engage with existing customers and reach new ones, SMM has
                    purpose-built data analytics that allows marketers to track
                    the success of their efforts and identify even more ways to
                    engage.
                    <br />
                    <br /> At the start of 2023, there were 4.76 billion social
                    media users globally—over 59% of the world’s population.
                    DataReportal. "Global Social Media Statistics." With over
                    80% of consumers reporting that social media—especially
                    influencer content—significantly impacts buying decisions,
                    marketers across industries are driving the evolution of
                    social media marketing (SMM) from a stand-alone tool to a
                    multipronged source of marketing intelligence on an
                    increasingly important—and growing—audience.
                  </p>
                  <div className="read-more">
                    <button href="https://en.wikipedia.org/wiki/Social_media_marketing" type="button" className="btn btn-info">
                      Read More
                    </button>
                  </div>
                </div>
              </article>
              <br />
              <article className="entry">
                <div className="entry-img">
                  <img
                    src="img/EmailM.png"
                    alt=""
                    width="100%"
                    className="img-fluid"
                  />
                </div>

                <h2 className="entry-title py-4">
                  <a href="https://en.wikipedia.org/wiki/Email_marketing">Email Marketing</a>
                </h2>

                {/* <div className="entry-meta">
                  <ul>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-person"></i>{" "}
                      <a href="blog-single.html">John Doe</a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-clock"></i>{" "}
                      <a href="blog-single.html">
                        <time datetime="2020-01-01">Jan 1, 2020</time>
                      </a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-chat-dots"></i>{" "}
                      <a href="blog-single.html">12 Comments</a>
                    </li>
                  </ul>
                </div> */}
                <br/>
                <div className="entry-content">
                  <p style={{textAlign:'justify'}}>
                    This beginner’s guide will explain what email marketing is,
                    how it works, and how to get started. By the end, you’ll
                    have a solid grasp of the basics along with the know-how to
                    launch an effective email marketing strategy for your
                    business. Email marketing is the most profitable and
                    cost-effective direct marketing channel, generating an
                    average return on investment of $42 for every $1 spent. For
                    this reason alone, email should be a key pillar of your
                    digital marketing strategy. Not doing any email marketing is
                    like leaving money laying out on the table.
                    <br />
                    <br />
                    But if you’re a bit confused about where to start, that’s
                    totally normal. Email is a vast discipline. It’s easy for
                    beginners to get lost in a sea of tools, techniques, and
                    terminology.
                  </p>
                  <div className="read-more">
                  <button href="https://en.wikipedia.org/wiki/Email_marketing" type="button" className="btn btn-info">
                      Read More
                    </button>
                  </div>
                </div>
              </article>

              {/* <div className="blog-pagination">
                <ul className="justify-content-center">
                  <li>
                    <a href="#">1</a>
                  </li>
                  <li className="active">
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#">3</a>
                  </li>
                </ul>
              </div> */}
            </div>

             <div className="col-lg-4">
              <div className="sidebar py-4" style={{backgroundColor:'skyblue',borderRadius:'2rem'}}>
               {/* <h3 className="sidebar-title">Search</h3>
                <div className="sidebar-item search-form">
                  <form action="">
                    <input type="text" />
                    <button type="submit">
                      <i className="bi bi-search"></i>
                    </button>
                  </form>
                </div> */}

                <h3 className="sidebar-title py-4">Web Development Langueges</h3>
                <br/>
                <div className="sidebar-item categories" style={{textAlign:'justify',paddingLeft:'4rem'}}>
                  <ul>
                    <li>
                        <h4><span>*</span>&nbsp;
                      <a href="https://www.w3schools.com/html/">
                    HTML 
                      </a>
                      </h4>
                    </li>
                    <li>
                        <h4><span>*</span>&nbsp;
                      <a href="https://www.w3schools.com/css/">
                        CSS 
                      </a>
                      </h4>
                    </li>
                    <li>
                        <h4><span>*</span>&nbsp;                      
                        <a href="https://www.w3schools.com/javascript/">
                        JavaScript 
                      </a>
                      </h4>
                    </li>
                    <li>
                    <h4><span>*</span>&nbsp;
                      <a href="https://react.dev/">
                        Reactjs 
                      </a>
                      </h4>
                    </li>
                    <li>
                    <h4><span>*</span>&nbsp;
                      <a href="https://www.w3schools.com/nodejs/">
                        Node.js 
                      </a>
                      </h4>
                    </li>
                    <li>
                    <h4><span>*</span>&nbsp;
                      <a href="https://www.w3schools.com/php/">
                        Php
                      </a>
                      </h4>
                    </li>
                  </ul>
                </div>
                <br/><br/>

                <h3 className="sidebar-title">Recent Posts</h3>
                <div className="sidebar-item recent-posts" style={{textAlign:'justify',paddingLeft:'4rem'}}>
                  <div className="post-item clearfix">
                    <img src="assets/img/blog/blog-recent-1.jpg" alt="" />
                    <h5>
                      Design & Development                      
                    </h5>
                    {/* <time datetime="2020-01-01">Jan 1, 2020</time> */}
                  </div>

                  <div className="post-item clearfix">
                    <img src="assets/img/blog/blog-recent-2.jpg" alt="" />
                    <h5>
                      SEO Optimization
                    </h5>
                    {/* <time datetime="2020-01-01">Jan 1, 2020</time> */}
                  </div>

                  <div className="post-item clearfix">
                    <img src="assets/img/blog/blog-recent-3.jpg" alt="" />
                    <h5>
                    Social Media Marketing                    
                    </h5>
                    {/* <time datetime="2020-01-01">Jan 1, 2020</time> */}
                  </div>

                  <div className="post-item clearfix">
                    <img src="assets/img/blog/blog-recent-4.jpg" alt="" />
                    <h5>                    
                      Email Marketing                      
                    </h5>
                    {/* <time datetime="2020-01-01">Jan 1, 2020</time> */}
                  </div>

                  {/* <div className="post-item clearfix">
                    <img src="assets/img/blog/blog-recent-5.jpg" alt="" />
                    <h4>
                      <a href="blog-single.html">
                        Et dolores corrupti quae illo quod dolor
                      </a>
                    </h4>
                    <time datetime="2020-01-01">Jan 1, 2020</time>
                  </div>*/}
                </div> 
                
{/* 
                <h3 className="sidebar-title">Tags</h3>
                <div className="sidebar-item tags">
                  <ul>
                    <li>
                      <a href="#">App</a>
                    </li>
                    <li>
                      <a href="#">IT</a>
                    </li>
                    <li>
                      <a href="#">Business</a>
                    </li>
                    <li>
                      <a href="#">Mac</a>
                    </li>
                    <li>
                      <a href="#">Design</a>
                    </li>
                    <li>
                      <a href="#">Office</a>
                    </li>
                    <li>
                      <a href="#">Creative</a>
                    </li>
                    <li>
                      <a href="#">Studio</a>
                    </li>
                    <li>
                      <a href="#">Smart</a>
                    </li>
                    <li>
                      <a href="#">Tips</a>
                    </li>
                    <li>
                      <a href="#">Marketing</a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <br/>
      {/* ------------------------------ */}
      <div className="container-xxl bg-white p-0">
       
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe
              className="gmap_iframe"
              width="100%"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=600&amp;height=480&amp;hl=en&amp;q=H - 161, BSI Park, Noida 63, Noida 301201&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            ></iframe>
          </div>
        </div>

        <Footer/>
      </div>
    </>
  );
}

export default Features;
