import React from 'react'
import Footer from './Footer'
import Header from './Header'

const Brand_Logo = () => {
  return (
    <>
    <div className="container-xxl bg-white p-0">
   
    

   
    <div className="container-xxl position-relative p-0">
        <Header/>

        <div className="container-xxl py-5 bg-danger hero-header">
            <div className="container my-5 py-5 px-lg-5">
                <div className="row g-5 py-5">
                    <div className="col-12 text-center">
                        <h1 className="text-white animated slideInDown">Logo & Branding</h1>
                        <hr className="bg-white mx-auto mt-0" style={{width: "90px"}}/>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><a className="text-white" href='/'>Home</a></li>
                                <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                                <li className="breadcrumb-item text-white active" aria-current="page">Logo & Branding</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div className="container-xxl py-5">
        <div className="container py-5 px-lg-5">
        <div className="row g-4">
        <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
            <div className=" service-item rounded text-center p-4 mymove" style={{
              backgroundColor: "coral",                
              animation: "mymove 5s infinite"}} >
                <i className="fa fa-3x fa-mail-bulk text-secondary mb-4"></i>
                <h5 className="mb-3">Makes You Memorable</h5>
                <p className="m-0">A well-thought-out, clear and simple logo makes the brand memorable and stands out in crowd.</p>
            </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="service-item  rounded text-center p-4 mymove" style={{
              backgroundColor: "coral",                
              animation: "mymove 5s infinite"}}>
                <i className="fa fa-3x fa-search text-secondary mb-4"></i>
                <h5 className="mb-3">Portrays A Professional Image</h5>
                <p className="m-0">A professionally designed logo offers the brand a visual identity and contributes to professional image..</p>
            </div>
        </div>
        <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
            <div className="service-item rounded text-center p-4 mymove" style={{
              backgroundColor: "coral",                
              animation: "mymove 5s infinite"}}>
                <i className="fa fa-3x fa-laptop-code text-secondary mb-4"></i>
                <h5 className="mb-3">Strengthens Brand</h5>
                <p className="m-0">A logo is used on the brand’s website, social media platforms, physical products and advertising materials. This strengthens the brand.</p>
            </div>
        </div>
    </div>
        </div>
    </div>





    <div className='container'>
    <article className="entry">
   

    <h2 className="entry-title py-4">
      Why Logo Matters?
    </h2>

    {/* <div className="entry-meta">
    <ul>
      <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">John Doe</a></li>
      <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jan 1, 2020</time></a></li>
      <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
    </ul>
  </div> */}
    <br />
    <div className="entry-content">
      <p style={{ textAlign: "justify" }}>
      Logos are the face of the company. It is the visual symbol of a business and is synonymous with the name. Being the main visual component of a company, logos appear on websites, letterheads, marketing and advertising materials, business cards and all branding creations. Logo design is the first step of creating a brand identity.

      
        <br />
        <br />
       Our company provides professional logo design services, specializing in custom logos that helps achieve business objectives. We design logos to accurately representyour brand.Get bespoke logos for SMEs, MMEs, individual brands and start-up companies. Differentiate the brand in a fast-growing and competitive marketplace.
      </p>
      
    </div>
  </article>
  </div>


  
  <div className='container'>
  <article className="entry">
 

  <h2 className="entry-title py-4">
    How We Work
  </h2>

  {/* <div className="entry-meta">
  <ul>
    <li className="d-flex align-items-center"><i className="bi bi-person"></i> <a href="blog-single.html">John Doe</a></li>
    <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <a href="blog-single.html"><time datetime="2020-01-01">Jan 1, 2020</time></a></li>
    <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
  </ul>
</div> */}
  <br />
  <div className="entry-content">
    <p style={{ textAlign: "justify" }}>
    Get complete transparency and knowledge about the steps taken to create a logo. Our designers and graphic team handles every task with the same minute precision irrespective of the project and strive to hand over a result of unparallel quality. Be assured of a timeless, unique and a memorable logo.
    <br />
    <br />
Share ideas with the design team. Explore with the designer everything you want to see in the logo. References and samples can also be shared. The designer will look at the existing competition, combine the client’s concept, do an in-depth research and come up with a unique solution to suit the brand. Our team makes sure that the logo stands out and gives the brand a perfect identity.
<br />
      <br />
The graphic team combines both traditional and technological methods to develop the sketches. With a focus on brand’s vision, brand message and a design that will surely have a long-lasting impression expert designers’handover a variety of ideas for client approval. After a detailed feedback from the client, modifications are made and only after a full consent, the approved logo is finalised.
<br />
      <br />
The graphic experts can digitize the approved logo perfectly.Our expert team are privy with all available modern software and can digitize or animate any logo exactly the way the client approves it. Be assured of a minimum turnover time with no compromise on work quality. Whether its complex or simplistic, the client always gets an impressive logo.
<br />
      <br />
Colors represent values. Colors can change our emotional state. It is vital that the color palette of logo blends with the business goals. Precise detailing of color also makes a logo captivating and captures the customer attention. The designer along with the graphic team inculcates the right mix of colors to the logo to make it appear and appealing and stylish at the same time.


    
      <br />
      <br />
      After a compete approval the final logo is handed over to the client via email in the following file formats: ai, pdf, eps, png, jpeg. Our company transfers full copyright on the agreed final designs to the client and claims no legal right to use it without the clients’ consent. With one of the best customized logo designing services, Our company all your needs covered. Throughout the entire designing phase, our support team will always remain at a contactable distance.
    </p>
    
  </div>
</article>
</div>



    <Footer/>
    </div>
    </>
  )
}

export default Brand_Logo
