import React from 'react'
import Footer from './Footer'
import Header from './Header'

const InstagramAdvertising = () => {
  return (
    <>
    <div className="container-xxl bg-white p-0">  
    <div className="container-xxl position-relative p-0">
        <Header/>

        <div className="container-xxl py-5 bg-danger hero-header">
            <div className="container my-5 py-5 px-lg-5">
                <div className="row g-5 py-5">
                    <div className="col-12 text-center">
                        <h1 className="text-white animated slideInDown">Instagram Advertising</h1>
                        <hr className="bg-white mx-auto mt-0" style={{width: "90px"}}/>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-center">
                                <li className="breadcrumb-item"><a className="text-white" href='/'>Home</a></li>
                                <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                                <li className="breadcrumb-item text-white active" aria-current="page">Instagram Advertising</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div className="container-xxl py-5">
        <div className="container py-5 px-lg-5">
        <div className="row g-4">
        <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
            <div className=" service-item rounded text-center p-4 mymove" style={{
                backgroundColor: "coral",                
                animation: "mymove 5s infinite"}}>
                <i className="fa fa-3x fa-mail-bulk text-secondary mb-4"></i>
                <h5 className="mb-3">Develop and Optimize Ads</h5>
                <p className="m-0">
                After creating a business profile, the central focus lies on development of Instagram ads. Our specialists create multiple versions of advertisements for the brand and optimize them with using hashtags. This method maximizes the engagement among Instagram posts.</p>
            </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="service-item  rounded text-center p-4 mymove" style={{
                backgroundColor: "coral",                
                animation: "mymove 5s infinite"}}>
                <i className="fa fa-3x fa-search text-secondary mb-4"></i>
                <h5 className="mb-3">Monitoring and Analyzing</h5>
                <p className="m-0">
                The team analyzes the advertisements on a weekly and monthly basis, monitors the progression of the ads and closes those ads which don’t work at all. We consistently evaluate the campaign's success data for making changes to improve efficiency. The team also makes sure that your ads are running without glitch and are inline with your budget.</p>
            </div>
        </div>
        <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
            <div className="service-item rounded text-center p-4 mymove" style={{
                backgroundColor: "coral",                
                animation: "mymove 5s infinite"}}>
                <i className="fa fa-3x fa-laptop-code text-secondary mb-4"></i>
                <h5 className="mb-3">Reporting</h5>
                <p className="m-0">
                Get a detailed Instagram advertising report each month to highlight the overall performance. It will display the amount of people reached, clicks received, as well as conversions achieved throughout the campaign. If you elect to do so, you can meet with our agency on a consistent basis to discuss the progress of your campaign.</p>
            </div>
        </div>
    </div>
        </div>
    </div>
    <div className='container'>
    <article className="entry">
    <h2 className="entry-title py-4">
    Instagram Advertising
    </h2>
    <br />
    <div className="entry-content">
      <p style={{ textAlign: "justify" }}>
     
      
      More than 70% Instagram users takes immediate action after going through an ad in their newsfeeds. With over 800 million monthly users, Instagram offers huge target audience for the businesses. The image-centric platform is one of the most utilized mobile platforms all over the world. And as it is a part of Facebook, the ads here get access to the most robust targeting features on the Internet.
      <br/>
      <br/>
      
      Our company renders a seamless experience to its clients in Instagram advertisement by driving awareness about the brand, increasing customer base by sharing the content among targeted audience. Whether clients want story ads, photo ads, video ads, carousel ads, collection ads, ads in explore, Our company can provide it all. Get more clicks, conversions and comprehensive brand recognition.
      </p>
     </div>
  </article>
  </div>
    <Footer/>
    </div>
    </>
  )
}

export default InstagramAdvertising
